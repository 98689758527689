/*
 * Created by andrew.stalker on 11/05/2016.
 * Location scanning and manual entry functionality
 * globals must be listed below to pass JSHint */
/* globals $, jQuery, jquery, getRequestPath, showAlert, isValidCharacters, padNumber, getProgramWarehouseType, getProgramTransactionCode, confirmScanSound, config,
   searchListForItem, searchListForItemCaseInsensitive, isNumeric, isValidString, searchList, doesProgramCheckLocation, findBarcodeMatch, playSuccessSound, checkCapacityForLocation, generateVacuityChoicePopup,
   movement_casks, getLabels, getProgramParameter, removeAllocations, clearCapacities, updateDataSources
 */

/**
 * @description Global object to store the location details for scanning movements
 * @type {object}
 * @property {String} warehouse Scanning warehouse or Trailer warehouse
 * @property {String} warehouseType The type of warehouse being scanned.
 * @property {String} bay Scanning bay or Trailer ID
 * @property {String} rack Optional Rack
 * @property {String} level Optional Level
 * @property {String} direction Optional Scan direction F or B
 * @property {String} scanned
 * @property {boolean} rackAndBayAreTheSame
 * @property {Number} capacity
 */
var scanLocation = {
  warehouse:"",
	warehouseType: "",
  bay:"",
  rack:"",
  level:"",
  direction:"",
  scanned:"",
	rackAndBayAreTheSame: false,
	capacity: 999
};

function setLocations(locations) {
	"use strict";
	localStorage.setObject("data/locations", locations);
}

function getAllLocations() {
	"use strict";
	return localStorage.getObject("data/locations");
}

function getScannerLocationCode() {
	"use strict";
	if (localStorage.getItem("runtime/location"))
	  return localStorage.getVariable("runtime/location");
	else return localStorage.getVariable("location");
}

function getScannerLocationName() {
	"use strict";
	if (localStorage.getItem("runtime/locationName"))
	  return localStorage.getVariable("runtime/locationName");
	else if (localStorage.getItem("locationName"))
		return localStorage.getVariable("locationName");
	else return localStorage.getVariable("locationname");
}

/**
 * @description Get the currently set location details
 * @returns {currentLocation} The current location set in LocalStorage
 */
function getCurrentLocation() {
	"use strict";
	// Note: this can be depreciated when single locations exist on the scanner
	var locations = getAllLocations();
	var thisLocationIndex = searchListForItem(locations, "location", getScannerLocationCode());
	return locations[thisLocationIndex];
}

/**
 * @description From the location choice screen the location is set, and allocations and trailers are updated.
 * @param {String} newLocation
 */
function setLocation(newLocation) {
	"use strict";

	var oldLocationCode = getScannerLocationCode(),
		locations = getAllLocations(),
		thisLocation = -1;

	for (var i = 0; i < locations.length; i++) {
		if (locations[i].location === newLocation) {
			thisLocation = i;
			break;
		}
	}
	//Set location variables.
	localStorage.setVariable("runtime/location", newLocation, true);
	localStorage.setVariable("runtime/locationName", locations[thisLocation].locname, true);
	setLocationWarehouses(locations[thisLocation].warehouses);

	// To be sure redundant data is cleared, we call some functions manually.
	// We only want to do this if the location (location code) actually changed.
	if (oldLocationCode !== "" && oldLocationCode !== locations[thisLocation].loccode) {
		removeAllocations(""); //Removes all allocations from the scanner
		clearScannerStartLocations(); // Clears starting positions.
		clearCapacities(); // Clear all the warehouse capacities because we have a different location.
		saveBatchesToStorage([]); // Clear batches on the scanner, an empty array removes all.
	}
	updateDataSources("settings"); // Updates all settings it possibly can, including standing data.
}

/**
 * @description Sets the current locations warehouses.
 * @param {Array} warehouses The warehouses if already known. If null the warehouses are determined by the scanners location from LS
 */
function setLocationWarehouses(warehouses) {
	"use strict";
	if (warehouses === null) {
		var thisLocation = getCurrentLocation();
		warehouses = (thisLocation)? thisLocation.warehouses: [];
	}
	localStorage.setObject("data/warehouses", warehouses);
}

function getLocationWarehouses() {
	"use strict";
	return localStorage.getObject("data/warehouses");
}

function getCustomLocationFlag() {
	"use strict";
	return localStorage.getVariable("settings/useCustomLocationFormats").toLowerCase() === 'true';
}

/**
 * @description Parses a location label into location values and validates values.
 * @param {string} labelScanned Label string from the scan input
 * @param callBack A function to run when either parse is completed, or an error occurs.
 * @returns {*} Will return if an error condition occurs.
 */
function parseScannedLocation(labelScanned, callBack) {
  "use strict";

	var systemLabelMapping = getLocationMapping(),
      prefix = localStorage.getItem("settings/scanPrefix");

	// Check and remove prefix. Set location indicator.
  scanLocation.scanned = "M";
  if(prefix !== "") {
    // check / remove prefix.
    if(labelScanned.substr(0,prefix.length) === prefix) {
      labelScanned = labelScanned.substr(prefix.length);
      scanLocation.scanned = "S";
    }
  }

	// Ensure all valid characters.
  var stringToValidate = labelScanned.replace(/\s+/g, "");
  if(!isValidCharacters(stringToValidate,"[a-zA-Z0-9]")) {
    showAlert("Bad Barcode Read.",{focus:"locationInput"});
		callBack(false);
    return;
  }

	var tempLocation = '',
		  tempWarehouse = '',
	    tempBay = '',
			tempRack = '',
			tempLevel = '',
			tempDirection = '';

	// Function to run once above variables have values depending on the mappings used.
	var postParseChecks = function() {

		// validate location on barcode
		if (tempLocation !== getCurrentLocation().loccode) {
			showAlert("Invalid location",{focus:"locationInput"});
			callBack(false);
			return;
		}

		// validate warehouse on barcode
		if (!isValidWarehouse(scanLocation.warehouse, "locationInput")) {
			callBack(false);
			return;
		}

		// now map each variable to the appropriate section of the barcode.
		// The warehouse is already mapped by this point.
		scanLocation.bay = padNumber(systemLabelMapping.bay.length, tempBay).toUpperCase();
		scanLocation.rack = padNumber(systemLabelMapping.rack.length, tempRack).toUpperCase();
		scanLocation.level = padNumber(systemLabelMapping.level.length, tempLevel).toUpperCase();
		scanLocation.direction = tempDirection;

		// Finally do the location checking functions
		doLocationChecks(function(passed, playSound) {
			if (passed) {
				playSuccessSound(playSound);
				callBack(true);
			} else {
				callBack(false);
			}
		});
	};

  if(getCustomLocationFlag()) {

		//Search for matching regular expression, identifying the label scanned.
		findBarcodeMatch(stringToValidate, "location", function(matchResult) {
			// barcode mapping was found.
			tempLocation = (matchResult.fields.location) ? (matchResult.fields.location).toUpperCase().trim() : '';
			tempWarehouse = (matchResult.fields.warehouse) ? (matchResult.fields.warehouse).toUpperCase().trim() : '';
			tempBay = (matchResult.fields.bay) ? (matchResult.fields.bay) : '';
			tempRack = (matchResult.fields.rack) ? (matchResult.fields.rack) : '';
			tempLevel = (matchResult.fields.level) ? (matchResult.fields.level) : '';
			tempDirection = (matchResult.fields.direction) ? (matchResult.fields.direction) : '';

			// Directly set the warehouse to the temp value before post parse checks occur.
			// This prevents accidental padding done in legacy code where all the warehouses had a uniform length.
			scanLocation.warehouse = tempWarehouse;

			postParseChecks();

		}, function() {
			showAlert("Barcode does not match a location label", {callback: function() {
				callBack(false);
			}});
		});

  } else {

		// Legacy functions when location mappings are from the barcode code table from locations.
		var scannedLabelMapping = systemLabelMapping;
		//Get values from input label
		tempLocation = labelScanned.substr(scannedLabelMapping.site.start, scannedLabelMapping.site.length).toUpperCase().trim();
		tempWarehouse = labelScanned.substr(scannedLabelMapping.warehouse.start, scannedLabelMapping.warehouse.length);
		tempBay = labelScanned.substr(scannedLabelMapping.bay.start, scannedLabelMapping.bay.length);
		tempRack = labelScanned.substr(scannedLabelMapping.rack.start, scannedLabelMapping.rack.length);
		tempLevel = labelScanned.substr(scannedLabelMapping.level.start, scannedLabelMapping.level.length);
		tempDirection = labelScanned.substr(scannedLabelMapping.frontBack.start, scannedLabelMapping.frontBack.length).toUpperCase();

		// check for Trimming function switched on, remove whitespace if so
		if (localStorage.getVariable("settings/trimFieldWhitespace").toUpperCase() === "TRUE") {
			tempWarehouse = tempWarehouse.trim();
			tempBay = tempBay.trim();
			tempRack = tempRack.trim();
			tempLevel = tempLevel.trim();
		}

	    // The warehouse transformation happens here before the post parse checks.
	    // This ensures that warehouse values are padded to the correct length in the mapping.
	    // This only supports systems not using custom mappings, and all warehouses at a site have the same chars.
	    scanLocation.warehouse = padNumber(systemLabelMapping.warehouse.length, tempWarehouse).toUpperCase().trim();

		postParseChecks();
  }

}

/**
 * @description Validates and saves location details
 * @param warehouseInput
 * @param bayInput
 * @param rackInput
 * @param levelInput
 * @param frontBackInput
 * @param callBack A function to run when the validation is finished. Will return false if validation checks fail.
 * @returns {boolean} Never returns true. Will return false if an error occurs, effectively stopping the program continuing.
 */
function validateManualLocationDetails(warehouseInput, bayInput, rackInput, levelInput, frontBackInput, callBack) {
	"use strict";
  var labelMap = getLocationMapping();
	var warehouseValue = $(warehouseInput).val();
  var frontBackValue = "";
  if (warehouseValue.length) {
    // warehouse input exists, append to label
		// Check for the warehouse suffix - Adding the parameter value automatically if it is set.
		warehouseValue += getProgramParameter("WS");

    if (isValidWarehouse(warehouseValue.toUpperCase().trim(), warehouseInput.replace("#", ""))) {
      // found
      scanLocation.warehouse = warehouseValue.toUpperCase().trim();
    } else {
      return false;
    }
  } else {
    showAlert("Please Enter a warehouse",{focus:String(warehouseInput.replace("#", ""))});
    return false;
  }

  var allLabels = getLabels(),
      thisWarehouseType = getWarehouseType(),
      warehouseLabels = (thisWarehouseType === "P")?allLabels.pallet:allLabels.racked;

  if(!isValidLocationInput(bayInput,warehouseLabels.bay)) return false;
  if(!isValidLocationInput(rackInput,warehouseLabels.rack)) return false;
  if(!isValidLocationInput(levelInput,warehouseLabels.level)) return false;
  if(!isValidLocationInput(frontBackInput,"Scan Direction")) return false;

  if($(frontBackInput).length) {
    frontBackValue = $(frontBackInput).val();
    if(!isValidScanDirection(frontBackValue)) {
      var elementName = frontBackInput.substr(1);
      showAlert("Invalid Direction", {focus: elementName});
      return false; // input wrong
    }
  }

  scanLocation.scanned = "M";
  if($(bayInput).length)scanLocation.bay = formatLocationInput(bayInput,labelMap.bay.length);
  if($(rackInput).length)scanLocation.rack = formatLocationInput(rackInput,labelMap.rack.length);
  if($(levelInput).length)scanLocation.level = formatLocationInput(levelInput,labelMap.level.length);
  if($(frontBackInput).length)scanLocation.direction = frontBackValue.toUpperCase();

	doLocationChecks(function(result) {
		callBack(result);
	});
}

/**
 * @description Perform additional checks to input data before saving the scanning location
 * @param {*} callback The function to run once checks are done and any alerts are closed.
 */
function doLocationChecks(callback) {
  "use strict";
  var programWarehouseType = getWarehouseType(),
		palletRackLabel = getLabels().pallet.rack,
		callBackResult = true,
		playSuccessSound = true;
	var alertOptions = {
		message: "",
		canCancel: false,
		cancelText: ""
	};

	// Check to see if pallet warehouse and no scan direction - set this to B by default.
	if ((getProgramTransactionCode() === "STOW") && (programWarehouseType === "P") && (scanLocation.direction === "")) scanLocation.direction = "B";

	// Check is for override of the racked bay functions
	if (localStorage.getItem("settings/overrideRackBay") !== "" && programWarehouseType === "R") overrideBayValue();

	// Check if we are using the pallet start locations for pallet put away
	if (doesScannerCheckStartLocations() && (programWarehouseType === "P") && doesProgramCheckLocation()) {
		// We are a pallet warehouse program, checking start locations, and the program has been told to do the check
		// Filter by warehouse, then bay. If nothing comes back for bay, then DRAMS thinks it is full
    var startLocation = getStartPositionForScanningLocation();
		alertOptions.canCancel = true;
		alertOptions.cancelText = "Re-enter";
		if(startLocation === null) {
			// Bay is full
			playSuccessSound = false;
			alertOptions.message = "DRAMS says this bay is full. Drops may occur.";
		} else {
			// Check rack and level value. Determine what is wrong, if anything.
			if(scanLocation.rack === startLocation.rack) {
				if (scanLocation.level !== startLocation.level) {
					playSuccessSound = false;
					if (scanLocation.level > startLocation.level) {
						alertOptions.message = "DRAMS has stock missing from this "+palletRackLabel+".";
					} else {
						alertOptions.message = "Drams says the next pallet should be at level " + startLocation.level + ". Drops may occur.";
					}
				}
			} else {
				// Racks don't match.
				playSuccessSound = false;
				if (scanLocation.rack > startLocation.rack) {
					alertOptions.message = "DRAMS has stock missing from this bay.";
				} else {
					alertOptions.message = "DRAMS says the "+palletRackLabel+" is full. Drops may occur.";
				}
			}
		}
	}

	// Check to see if the program's transaction code needs to validate capacity.
	var transactionCodes = localStorage.getObject("data/transactionCodes");
	var thisTransaction = transactionCodes.filter(function(el) {
		return el.transactionCode === getProgramTransactionCode();
	})[0];

	if (thisTransaction && thisTransaction.validateCapacity) {

		// We only want to filter by level if we are not a palletised warehouse.
		var levelToCheck = (scanLocation.warehouseType !== 'P')? scanLocation.level : '';
		checkCapacityForLocation(scanLocation.warehouse, scanLocation.bay, scanLocation.rack, levelToCheck, function(options) {
			if (options.length === 0) {
				// show error
				alertOptions.message = "This warehouse location does not have any capacity.";
				callBackResult = false;
				finishLocationChecks(alertOptions, callback, callBackResult, playSuccessSound);
			} else if (options.length === 1) {
				// must already have stock, use that type given
				scanLocation.capacity = options[0].capacity;
				finishLocationChecks(alertOptions, callback, callBackResult, playSuccessSound);
			} else {
				// present dialog to choose vacuity type
				generateVacuityChoicePopup(options, function(capacity) {
					scanLocation.capacity = capacity;
					finishLocationChecks(alertOptions, callback, callBackResult, playSuccessSound);
				});
			}
		});
	} else {
		finishLocationChecks(alertOptions, callback, callBackResult, playSuccessSound);
	}
}

/**
 * @description Should only be called from doLocationChecks when ready.
 * @param alertOptions
 * @param callback
 * @param callBackResult
 * @param playSuccessSound
 */
function finishLocationChecks(alertOptions, callback, callBackResult, playSuccessSound) {
	"use strict";
	// finally run the callback to do necessary actions from the calling function.
	// IF there is an alert message then we show this and wait to callback. Otherwise we callback
	if (alertOptions.message !== "") {
		showAlert(alertOptions.message,{type:"info", canCancel: alertOptions.canCancel, cancelText: alertOptions.cancelText, callback: function() {
			callback(callBackResult, playSuccessSound);
		}});
	} else {
		callback(callBackResult, playSuccessSound);
	}
}

/**
 * @description Check to see if allowing a scan will breech the capacity for the warehouse location
 * @returns {boolean} True if the capacity is breached and the scan cannot be saved. False if breached but only warn the user.
 */
function isLocationCapacityBreached() {
	"use strict";
	// count already scanned
	var scannedCasks = movement_casks.length;
	var isHardStop = localStorage.getVariable("settings/capacityHitAction") === "stop";

	if (scannedCasks < scanLocation.capacity) {
		// If we refactor the validateRackLimit function here, then we additionally need to check
		// If scanned casks is one less than capacity, then show warning, to indicate we have reached capacity.
		if (scanLocation.capacity - scannedCasks === 1) {
			showAlert("Maximum casks (" + scanLocation.capacity + ") have now been scanned into this level.", {
				focus: "scanInput",
				type: "info"
			});
		}
		return false; // We haven't scanned to capacity so all is okay.
	} else {
		// Adding another scan to movement_casks would breech the capacity set for the warehouse location.
		var alertType = (isHardStop) ? 'error' : 'info';
		if (isHardStop) {
			showAlert("Maximum casks (" + scanLocation.capacity + ") have already been scanned. Cannot save this scan.", {focus: "scanInput", type: alertType});
		} else {
			// can we callback with result from the warning?
			showAlert("Maximum casks (" + scanLocation.capacity + ") have already been scanned. This scan has been saved.", {focus: "scanInput", type: alertType});
		}

		// showAlert("Maximum casks (" + scannedCasks + ") have already been scanned.", {focus: "scanInput", type: alertType});
		// Check the parameter to either stop or show the warning only.
		return isHardStop;
	}
}

/**
 * @description Finds the start record for the scanning Warehouse / Bay. This will confirm if the Bay / Stack / Level is correct.
 * @returns {*} An object with the start details if the bay is not full. Otherwise null.
 */
function getStartPositionForScanningLocation() {
	"use strict";
	var possibleStartLocations = localStorage.getObject("data/palletStartPositions");
  var filteredStartLocations = possibleStartLocations.filter(function (el) {
		return el.warehouse === scanLocation.warehouse && el.bay === scanLocation.bay;
	});
	return (filteredStartLocations.length > 0)? filteredStartLocations[0]: null;
}

/**
 * @description Creates a location label mapping object to validate location details.
 * @returns {{site: {start: number, length: number}, warehouse: {start: number, length: number}, bay: {start: number, length: number}, rack: {start: number, length: number}, level: {start: number, length: number}, frontBack: {start: number, length: number}}}
 */
function getLocationMapping(customMapping) {
	"use strict";
  var locationMap = (typeof customMapping === 'undefined')?getLocationLabelMapping().split(""):customMapping;
  var mapping = {
    site: {
      start: 0,
      length: 0
    },
    warehouse: {
      start: 0,
      length: 0
    },
    bay: {
      start: 0,
      length: 0
    },
    rack: {
      start: 0,
      length: 0
    },
    level: {
      start: 0,
      length: 0
    },
    frontBack: {
      start: 0,
      length: 0
    }
  };

  for (var i = locationMap.length; i >= 0; i--) {
    switch (locationMap[i]) {
      case "S":
        mapping.site.start = i;
        mapping.site.length++;
        break;
      case "W":
        mapping.warehouse.start = i;
        mapping.warehouse.length++;
        break;
      case "B":
        mapping.bay.start = i;
        mapping.bay.length++;
        break;
      case "R":
        mapping.rack.start = i;
        mapping.rack.length++;
        break;
      case "L":
        mapping.level.start = i;
        mapping.level.length++;
        break;
      case "F":
        mapping.frontBack.start = i;
        mapping.frontBack.length++;
        break;
    }
  }
  return mapping;
}

/**
 * @description Finds the appropriate mapping to use for location and warehouse type
 * @returns {*}
 */
function getLocationLabelMapping() {
	"use strict";
  var locations = getAllLocations();
  var thisLocationIndex = searchListForItem(locations, "location", getScannerLocationCode());
  var thisLocation = locations[thisLocationIndex];
  var warehouseType = getProgramWarehouseType();

  switch (warehouseType) {
    case "RACK":
      return thisLocation.rackedMap;
    case "PALL":
      return thisLocation.palletisedMap;
    case "DUNN":
      return thisLocation.dunnageMap;
    default:
      return thisLocation.labelMap;
  }
}

function getSpecificLocationMapping(warehouseTypeCode) {
  "use strict";
  var locations = getAllLocations();
  var thisLocationIndex = searchListForItem(locations, "location", getScannerLocationCode());
  var thisLocation = locations[thisLocationIndex];
  var mapping = "";
  switch (warehouseTypeCode) {
    case "R":
      mapping = thisLocation.rackedMap;
      break;
    case "P":
      mapping = thisLocation.palletisedMap;
      break;
    case "D":
      mapping = thisLocation.dunnageMap;
      break;
    default:
      mapping = thisLocation.labelMap;
      break;
  }
  return getLocationMapping(mapping);
}

/**
 * @description Checks a manual location field is valid
 * @param {String} element Selector ID for the element
 * @param {string} label the field label to show if an error occurs
 * @returns {boolean} Returns true if valid
 */
function isValidLocationInput(element, label) {
	"use strict";
  if ($(element).length) {
    //Is on form
    var inputValue = $(element).val(),
        elementName = element.substr(1);
    if (inputValue !== "") {
      if(isValidString(inputValue, "[a-zA-Z0-9]{" + inputValue.length + "}")) {
        return true;
      } else {
        showAlert("Invalid " + label, {focus: elementName});
        return false;
      }
    } else {
      showAlert("Enter " + label, {focus: elementName});
      return false; // input missed
    }
  } else {
    return true; // element doesn't exist
  }
}

function formatLocationInput(inputElement,length) {
	"use strict";
  var inputValue = $(inputElement).val();
  if (isNumeric(inputValue)) {
    inputValue = padNumber(length,inputValue);
  }
  return inputValue.toUpperCase();
}

/**
 * @description Overrides the Bay value with the Rack value. Only done if the programs Transaction code is included in
 * the list that should be overwritten.
 */
function overrideBayValue() {
	"use strict";
  var programTransactionCode = getProgramTransactionCode();
  var overrideTransactions = localStorage.getVariable("settings/overrideRackBay").split(",");
	// racked bay is a list of transaction - if the transaction code is not in the list, then its not overwritten.
  if (searchList(programTransactionCode, overrideTransactions)) {
    // set and correct trans type - override
    scanLocation.bay = scanLocation.rack;
		scanLocation.rackAndBayAreTheSame = true;
  }
}

/**
 * @description Checks to see that the given warehouse is valid
 * @param {String} enteredWarehouse The warehouse
 * @param {String} inputId element to focus on when error occurs. (Do not include #)
 * @param {String} [customType] To override the program and validate ad hoc.
 * @returns {boolean} True if valid
 */
function isValidWarehouse(enteredWarehouse, inputId, customType) {
  "use strict";
  var warehouseType = !customType? getProgramWarehouseType().substring(0, 1):customType,
      warehouses = getLocationWarehouses(),
      index = searchListForItemCaseInsensitive(warehouses, "warehouse", enteredWarehouse);

  if (index !== null) {
	  scanLocation.warehouseType = warehouses[index].warehouseType;
	  if (warehouseType === "A") {
      return true; // A for all, to override when a program works for all EG DROP.
    }
    // for all other types
    if (warehouseType === warehouses[index].warehouseType) {
      return true;
    }
    showAlert("Invalid warehouse. Warehouse type does not match program.",{focus:inputId});
    return false;
  }
  showAlert("Invalid warehouse.",{focus:inputId});
  return false;
}

/**
 * @description Validates the scan direction entered. Possible values F or B
 * @param {string} string
 * @returns {boolean}
 */
function isValidScanDirection(string) {
	"use strict";
  return (string.toUpperCase() === "F" || string.toUpperCase() === "B");
}

//New functions begin here.
//These are designed to be generic and useful anywhere
/**
 * @typedef {Object} currentLocation
 * @property {String} location Long location code
 * @property {String} locname Location name
 * @property {String} loccode Scanning location code
 * @property {Boolean} useFlowLog Set to true if the location uses flow log for filling operations
 * @property {String} labelMap Default location barcode label mapping
 * @property {String} rackedMap Barcode label mapping for a racked warehouse
 * @property {String} palletisedMap Barcode label mapping for a palletised warehouse
 * @property {String} dunnageMap Barcode label mapping for a dunnage warehouse
 * @property {Array<Object>} warehouses Warehouses at location
 */

/**
 * @description Checks to see if the current scanning location uses flow log for filling operations
 * @returns {Boolean}
 */
function doesLocationUseFlowLog() {
	"use strict";
	var thisLocation = getCurrentLocation();
	return thisLocation.useFlowLog;
}

/**
 * @description Checks to see if the scanner has been configured to check the start scanning locations
 * @returns {boolean}
 */
function doesScannerCheckStartLocations() {
	"use strict";
	return (localStorage.getVariable("settings/checkPalletStartPositions").toUpperCase() === "TRUE");
}

//TODO this will be depreciated if the location relevant data can be downloaded when changing location.
/**
 * @description Clears downloaded start positions, which is necessary when changing location
 */
function clearScannerStartLocations() {
	"use strict";
	localStorage.setObject("data/palletStartPositions",[]);
}

/**
 * @description Gets the current warehouse type set by the active program. This sanitizes any mix of inputs.
 * @returns {String} R,P,D,or A for all. Default to R
 */
function getWarehouseType() {
  "use strict";
  var programWarehouseType = getProgramWarehouseType();
  switch (programWarehouseType) {
    case "RACK":
    case "R":
      return "R";
    case "PALL":
    case "P":
      return "P";
    case "D":
    case "DUNNAGE":
      return "D";
    case "ALL":
    case "A":
      return "A";
    default:
      return "R";
  }
}